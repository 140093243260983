<form style="background-color: #101D2A; border: 2px solid #fff; border-radius: 16px;">
    <div class="row" style="background-color: #101D2A; border-top-left-radius: 16px; border-top-right-radius: 16px;">
        <div class="col-12">
            <h3 style="color: #fff; font-weight: bold;">Log In</h3>
        </div>
        <div style="color: #fff;" class="col-12">
            <mat-form-field appearance="standard">
                <mat-label class="p-3" style="color: #fff;">Cell Number</mat-label>
                <input matInput required intput name="mobilenumber" input type="text" [(ngModel)]="credentials.mobilenumber" (ngModelChange)="trimSpaces()">
                <mat-icon matSuffix><i class="fa fa-phone"></i></mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="row" style="background-color: #101D2A; border-bottom-left-radius: 16px; border-bottom-right-radius: 16px;">
        <div class="col-12 py-0 my-1">
            <button type="submit" class="button sign-in-button flex card card-body mat-textbox" (click)="signIn()">
                <h3 class="button-text">
                    <b>Send OTP</b>
                </h3>
            </button>
        </div>

    </div>
</form>