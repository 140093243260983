import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';

import { ApiService } from 'app/ts/ApiService';
import { Consent, SaveClientForCallback, clientAffordabilityData } from 'app/ts/models/broker-classes';
import { Countries, DateModel, FindClient, Products } from 'app/ts/models/broker-interfaces';
import { ActivatedRoute } from '@angular/router';
import { IdentificationTypeService } from 'app/ts/services/identification-type.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { LoanApplication } from 'app/ts/models/loan-classes';
import { OtpComponent } from '../authenticate/otp/otp.component';
import { MatDialog } from '@angular/material/dialog';
import { OtpLogin } from 'app/ts/models/user-credentials';
import { DataSharingService } from 'app/data-sharing-service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss', '../../forms.shared.scss']
})
export class PersonalDetailsComponent implements OnInit {
  loading: boolean;
  idTypeValue: number = 0;
  clientId: number = 0;
  idtypes: string[] = ['RSA ID Number', 'Passport Number'];
  countries: Countries[];
  selectedCountries: Countries[];
  client = {} as SaveClientForCallback;
  clientFind: FindClient = {
    Title: '',
    FirstName: '',
    LastName: '',
    ExternalID: '',
    MobileNumber: '',
    Email: '',
    StaffId: 718,
    isPassport: false,
    DateOfIssue: '',
    ExpiryDate: '',
    DateOfBirth: '',
    Country: 0,
    ClientId: 0,
    LoanStatusId: 0
  }
  consent = {} as Consent;
  passed: boolean;
  error: string;
  accountHolder: string;
  ngDateOfBirth: Date;
  ngDateOfIssue: Date;
  ngExpiryDate: Date;
  clientAffordabilityData: any;

  selectedProduct: any;
  minmumTerm: number = 1;
  maxmumTerm: number = 24;
  loanApplication: LoanApplication = {
    productId: 0,
    username: '',
    amount: 0,
    term: 0,
    type: '',
    selectedProduct: '',
    clientId: 0,
    orderId: 0,
  };

  isPassport$ = this._identificationType.isPassport;
  staffId: "718";
  subdomain: 'Broker Portal - Manual Upload';

  dateModel: DateModel = {
    ngDateOfBirth: new Date(),
    ngDateOfIssue: new Date(),
    ngExpiryDate: new Date(),
  };


  title = 'detect-route-change';
  currentRoute: string;

  radio_list = [
    {
      id: '1',
      name: 'radio_id',
      value: 0,
      label: 'RSA ID Number',
      disabled: false,
      checked: true,
    }, {
      id: '2',
      name: 'radio_passport',
      value: 1,
      label: 'Passport',
      disabled: false,
      checked: false,
    }
  ];
  loanterm: number = 0;
  clientProfile: any;
  EditProfile = false;
  productId: string;
  loanAmount: string;
  ClientDetails: any;
  ClientTokenDetails: any;
  addressForm: FormGroup;
  AddressObjects: any;
  StateOptions: any;
  addressTypeIdOptions: any;
  countryIdOptions:any;
  clientAddressSet: boolean;
  oldLoan: any;

  @Input() clientAffordabilityDataEvent: any;
  // @Input() clientAffordabilityDataEvent: EventEmitter<any>;
  @Input() updateClient: any;
  @Input() newLoan: any;
  @Input() existingClient: any;
  @Input() interestRate: any;

  isDataValid:boolean;


  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private _identificationType: IdentificationTypeService,
    public _productSelect: ProductSelectService,
    private dialog: MatDialog,
    private dataSharingService: DataSharingService
  ) {
    
    
    this.api.getAddressCountries().subscribe((data: any) => {
      this.AddressObjects = data;
      this.StateOptions = data.stateProvinceIdOptions;
      this.addressTypeIdOptions = data.addressTypeIdOptions;
      this.countryIdOptions = data.countryIdOptions;
    });
  }


  personalDetails: FormGroup;
  canProceed: boolean = false;

  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();
  productList$ = this._productSelect.productList;

  ngOnInit() {
    this.isDataValid = this.evaluateData(this.clientAffordabilityDataEvent);


    var newClientDetails = JSON.parse(localStorage.getItem("clientDetailsData"));
    var sessionStorageClient = JSON.parse(sessionStorage.getItem("ClientInfo"));

    if (newClientDetails != null || newClientDetails != undefined) this.newLoan = true;
    if (sessionStorageClient != null || sessionStorageClient != undefined) this.updateClient = true;
    this.clientProfile = sessionStorageClient;
    if (this.updateClient){
      this.EditProfile = true;
      
      // this.personalDetails.patchValue(clientDetails.client);
      this.setPersonalDetailsForm();
    }

    if (this.newLoan) {
      this.EditProfile = false;
      this.setPersonalDetailsForm();
      this.personalDetails.patchValue(newClientDetails);
      if (this.clientProfile) {
        this.personalDetails.controls['email'].setValue(this.clientProfile.client.email)
      }
    }

    this.consent = {} as Consent;

    
    this.getClientProducts();

    this.personalDetails.controls['country'].setValue(50)
    this.personalDetails.controls['nationality'].setValue('South African')

    // this.EditProfile = this.evaluateData(this.clientAffordabilityDataEvent);
  }

  evaluateData(data: any): boolean {
    // Replace the following condition with your actual condition
    return data;
  }

  getClientProducts() {
    this.staffId = "718";
    this._productSelect
      .getStaffProductList(this.staffId)
      .subscribe((data: Products[]) => {
        const selectedProduct = this.findSelectedProduct(data);
        
        localStorage.setItem("selectedProduct", JSON.stringify(selectedProduct));
        if (selectedProduct) {
          this.updateSelectedProduct(selectedProduct);
        }
      });
  }

  
  findSelectedProduct(data: Products[]): Products | undefined {
    if (this.interestRate === 0.03) {
      return data[0];
    } else {
      return data[1]
    }
    // if (!this.productId) {
    //     return data.length > 0 ? data[1] : undefined;
    // }
    
    // return data.find((item) => Number(this.productId) === item.id);
  }
  
  updateSelectedProduct(selectedProduct: Products) {
    if(selectedProduct)
    {
      this.selectedProduct = selectedProduct;
      this.maxmumTerm = selectedProduct.maxNumberOfRepayments;
      this.minmumTerm = selectedProduct.minNumberOfRepayments;
    
      this.personalDetails.patchValue({
        loanterm: this.maxmumTerm,
      });
      let clientDetailsData:any = localStorage.getItem("clientDetailsData");

      // Step 2: Parse the JSON string into an object
      if (clientDetailsData) {
          clientDetailsData = JSON.parse(clientDetailsData);

          // Step 3: Modify the loanterm property
          clientDetailsData.loanterm = this.maxmumTerm;

          // Step 4: Convert the object back to a JSON string
          const updatedClientDetailsData = JSON.stringify(clientDetailsData);

          // Step 5: Save the updated JSON string back to localStorage
          localStorage.setItem("clientDetailsData", updatedClientDetailsData);
      } else {
          // Handle the case where the item doesn't exist in localStorage
          console.log("clientDetailsData does not exist in localStorage.");
      }
    }

  }

  
  submitLoanApplition(clientId)
  {
    
    var clientDetails = JSON.parse(localStorage.getItem("clientDetailsData"));
    
    this.loanApplication.clientId = clientId;
    this.loanApplication.username = `${this.client.surname.replace(/^[,;]*(.*?)[,; ]*$/, "$1")}${this.client.name.replace(/^[,;]*(.*?)[,; ]*$/, "$1")}`
    this.loanApplication.amount = Math.round(Number(clientDetails.loanamount));
    this.loanApplication.term = this.personalDetails.get('loanterm').value;
    this.loanApplication.type = "individual-personal";
    this.loanApplication.selectedProduct = "";
    this.loanApplication.productId = this.selectedProduct.id;
    //this.loanApplication.orderId = this.ClientTokenDetails.orderId;

    this.api.submitLoanApplication(this.loanApplication).subscribe((data: any) => {

      if (data.loanId !== 0 && data.loanId !== undefined) {
        //sessionStorage.setItem("loanId", data.loanId);
        //sessionStorage.setItem('LoanApplication', JSON.stringify(this.loanApplication));
        this.dataSharingService.setLoanApplication(this.loanApplication);
        //this.openSnackBar("Loan submitted", 'success-snackbar');
      }
      else {
        this.openSnackBar(data, 'error-snackbar');
      }
    })
  }


  ngAfterViewChecked(): void {

    this.dataSharingService.selectedProduct$.subscribe(product => {
      this.updateSelectedProduct(product);
    });

    this.dataSharingService.clientDetails$.subscribe(details => {
     

      if(details != null && this.ClientTokenDetails == null)
      {
        this.ClientTokenDetails = details;

        this.staffId = this.ClientTokenDetails.staffId;
        this.loanAmount = this.ClientTokenDetails.totalIncl

        this.personalDetails.patchValue({
          name: this.ClientTokenDetails.name,
          mobileNumber: this.ClientTokenDetails.mobile,
          email: this.ClientTokenDetails.email,
          surname: this.ClientTokenDetails.surname,
          loanamount: this.ClientTokenDetails.totalIncl,
        });

        this.personalDetails.controls.country.patchValue('South Africa')
        this.personalDetails.controls['nationality'].setValue('South African')

      }
      
    });

  }

  paramsChanged() {
    this.route.params.subscribe(params => {
      if (this.clientId != params['clientId']) {
        this.clientId = +params['clientId'];
        sessionStorage.setItem('staffId', this.clientId.toString());

        if (this.clientId != 0) {
          this.populateSearchClient();
        } else {
          this.setPersonalDetailsForm();
        }

      }
    });
  }

  populateSearchClient() {
    this.paramsChanged();

    if (this.clientId == 0) {
      this.clientFind = JSON.parse(sessionStorage.getItem('ClientResult'));
      this.consent = JSON.parse(sessionStorage.getItem('ConsentResult'));

      //Show passport or ID fields
      this.radio_list.forEach(r => {

        if (this.clientFind.isPassport) {
          if (r.name == 'radio_passport') {
            r.checked = true;
          }
          if (r.name == 'radio_id') {
            r.checked = false;
          }
          if (r.id == '2') {
            this.radioSelect(Number(r.id) - 1);
          }
        }
        else {
          if (r.name == 'radio_passport') {
            r.checked = false;
          }
          if (r.name == 'radio_id') {
            r.checked = true;
          }
          if (r.id == '1') {
            this.radioSelect(Number(r.id) - 1)
          }

        }

      });

      this.client.name = this.clientFind.FirstName;
      this.client.surname = this.clientFind.LastName;
      this.client.idnumber = this.clientFind.ExternalID;
      this.client.cellnumber = this.clientFind.MobileNumber;
      this.client.country = this.clientFind.Country;
      this.client.dateOfBirth = this.clientFind.DateOfBirth;
      this.client.dateOfIssue = this.clientFind.DateOfIssue;
      this.client.email = this.clientFind.Email;
      this.client.expiryDate = this.clientFind.ExpiryDate;
      this.client.isPassport = this.clientFind.isPassport;
      this.client.staffId = this.clientFind.StaffId;
      this.client.loanStatusId = this.clientFind.LoanStatusId;

      this.personalDetails.controls.idNumber.patchValue(this.client.idnumber);
      this.personalDetails.controls.name.patchValue(this.client.name);
      this.personalDetails.controls.surname.patchValue(this.client.surname);
      this.personalDetails.controls.email.patchValue(this.client.email);
      this.personalDetails.controls.mobileNumber.patchValue(this.client.cellnumber);
      this.personalDetails.controls.country.patchValue(this.client.country);

      this.dateModel.ngDateOfBirth = new Date(this.client.dateOfBirth);
      this.dateModel.ngDateOfIssue = new Date(this.client.dateOfIssue);
      this.dateModel.ngExpiryDate = new Date(this.client.expiryDate);

      this.personalDetails.controls.popiaConsent.setValue(this.consent.popi);
      this.personalDetails.controls.marketingConsent.setValue(this.consent.marketingEmail);

      sessionStorage.setItem('clientId', this.clientFind.ClientId.toString());
      sessionStorage.setItem('loanStatusId', this.client.loanStatusId.toString());

      if (this.client.loanStatusId == 300) {
        this.personalDetails.disable();
      }
      else {
        this.personalDetails.enable();
      }

    }
    else {
      sessionStorage.removeItem('ClientResult');
      sessionStorage.removeItem('ConsentResult');

      this.personalDetails.controls.idNumber.patchValue('');
      this.personalDetails.controls.name.patchValue('');
      this.personalDetails.controls.surname.patchValue('');
      this.personalDetails.controls.email.patchValue('');
      this.personalDetails.controls.mobileNumber.patchValue('');
      this.personalDetails.controls.country.patchValue('');

      this.personalDetails.controls.popiaConsent.setValue(false);
      this.personalDetails.controls.marketingConsent.setValue(false);
    }

  }

  setPersonalDetailsForm() {
    if (!this.EditProfile)
    {
      sessionStorage.setItem('clientId', "0");
      this.personalDetails = this.formBuilder.group({
        idNumber: ['', [Validators.required, Validators.maxLength(13)]],
        name: ['', Validators.required],
        surname: ['', Validators.required],
        email: ['', [Validators.email, Validators.required]],
        mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        country: [''],
        dateOfBirth: [''],
        dateOfIssue: [''],
        expiryDate: [''],
        popiaConsent: [true, [Validators.requiredTrue]],
        creditCheckConsent: [true, [Validators.requiredTrue]],
        marketingConsent: [true,[Validators.requiredTrue]],
        loanterm:[''],
        loanamount:['' ,],
        title: [''],
        nationality: ['South Africa'],
        
      });



      this.radio_list.forEach(r => {
        if (r.name == 'radio_passport') {
          r.checked = false;
        }

        if (r.name == 'radio_id') {
          r.checked = true;
        }

        if (r.id == '1') {
          this.radioSelect(Number(r.id) - 1)
        }
      });
    }else{

      this.personalDetails = this.formBuilder.group({
        idNumber: [this.clientProfile.client.externalId, [Validators.required, Validators.minLength(13)]],
        name: [this.clientProfile.client.firstname, Validators.required],
        surname: [this.clientProfile.client.lastname, Validators.required],
        email: [this.clientProfile.client.email, [Validators.email, Validators.required]],
        mobileNumber: [this.clientProfile.client.mobileNo, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        country: [this.clientProfile.client.country],
        dateOfBirth: [this.clientProfile.client.dateOfBirth],
        dateOfIssue: [this.clientProfile.client.dateOfIssue],
        expiryDate: [this.clientProfile.client.expiryDate],
        popiaConsent: [true,],
        creditCheckConsent: [true,],
        marketingConsent: [true],
        loanterm:[''],
        loanamount:[''],
        title: [this.clientProfile.client.title],
        nationality: ['South Africa'],
      });

      // this.personalDetails;
    }

  }

  radioSelect(value: number) {
    this.idTypeValue = value;
    if (value == 0) {
      this.client.isPassport = false;
      this.personalDetails.get('dateOfIssue').clearValidators();
      this.personalDetails.get('expiryDate').clearValidators();
      this.personalDetails.get('dateOfBirth').clearValidators();
      this.personalDetails.get('expiryDate').clearValidators();
      this.personalDetails.get('country').clearValidators();
      this.personalDetails.get('idNumber').setValidators([Validators.required, Validators.minLength(13)]);
      this.personalDetails.get('idNumber').setErrors({ 'idNumber': true });
    }

    if (value == 1) {
      this.client.isPassport = true;
      this.personalDetails.get('dateOfIssue').setValidators(Validators.required);
      this.personalDetails.get('expiryDate').setValidators(Validators.required);
      this.personalDetails.get('dateOfBirth').setValidators(Validators.required);
      this.personalDetails.get('idNumber').setValidators([Validators.required]);
      this.personalDetails.get('idNumber').setErrors({ 'idNumber': true });
    }

    this._identificationType.updateIdentificationType(this.client.isPassport);
  }

  checkAge(idNumber: string): ValidatorFn {
    return (control: AbstractControl) => {
      var s = idNumber.substring(0, 6);

      return control?.value === control?.parent?.controls[idNumber].value ? null : { isMatching: true };
    }
  }

  formatLabel(value: number): string {
    if (value >= 1) {
      return `${value}`;
    }

    return `${value}`;
  }

  save(): void {
    this.personalDetails.markAllAsTouched();
    if (this.personalDetails.valid) {
      this.loading = true;
      this.ClientTokenDetails = JSON.parse(sessionStorage.getItem("ClientTokenDetails"));
      if(this.ClientTokenDetails != null)
      {
        this.staffId = this.ClientTokenDetails.staffId;
        this.loanAmount = this.ClientTokenDetails.totalIncl
      }

      this.client.isPassport = this.isPassport$;

      if (!this.client.isPassport) {
        this.client.country = 20;
      }

      this.client.staffId = Number("718");
      this.client.idnumber = this.personalDetails.controls['idNumber'].value;
      this.client.name = this.personalDetails.controls['name'].value;
      this.client.surname = this.personalDetails.controls['surname'].value;
      this.client.email = this.personalDetails.controls['email'].value;
      this.client.cellnumber = this.personalDetails.controls['mobileNumber'].value;
      this.client.dateOfBirth = this.personalDetails.controls['dateOfBirth'].value;
      this.client.dateOfIssue = this.personalDetails.controls['dateOfIssue'].value;
      this.client.expiryDate = this.personalDetails.controls['expiryDate'].value;
      this.client.country = this.personalDetails.controls['country'].value;
      this.client.title = this.personalDetails.controls['title'].value;


      var url = window.location.href;

      let subdomain = this.getSubdomain(window.location.href);

      if (url.includes('localhost')) {
        subdomain = "uat-payroll";
      }
      
      
      this.client.subDomain = subdomain;
      if(this.EditProfile)
      {
          //upldateClient country
          this.client.id = this.clientProfile.client.id;
          this.client.country  = 20;

          this.clientFind  = {
            Title: '',
            FirstName: '',
            LastName: '',
            ExternalID: '',
            MobileNumber: '',
            Email: '',
            StaffId: 0,
            isPassport: false,
            DateOfIssue: '',
            ExpiryDate: '',
            DateOfBirth: '',
            Country: 0,
            ClientId: 0,
            LoanStatusId: 0
          }

          this.clientFind.FirstName = this.personalDetails.controls['name'].value;
          this.clientFind.LastName = this.personalDetails.controls['surname'].value;
          this.clientFind.ExternalID = this.personalDetails.controls['idNumber'].value;
          this.clientFind.MobileNumber = this.personalDetails.controls['mobileNumber'].value;
          this.clientFind.Email = this.personalDetails.controls['email'].value;
          this.clientFind.ExpiryDate = this.personalDetails.controls['expiryDate'].value;
          this.clientFind.isPassport = this.clientProfile.client.isPassport;
          this.clientFind.StaffId =  Number(sessionStorage.getItem('staffId'));
          this.clientFind.Country = this.personalDetails.controls['country'].value;
          this.clientFind.DateOfBirth = this.personalDetails.controls['dateOfBirth'].value;
          this.clientFind.DateOfIssue = this.personalDetails.controls['dateOfIssue'].value;
          this.clientFind.Title = this.personalDetails.controls['title'].value;
          this.clientFind.ClientId = this.clientProfile.client.id;
          // this.clientFind.Country = 20;

          this.clientFind.LoanStatusId = 0;

          this.api.upldateClient(this.clientFind).subscribe((data: any) => {
            this.updateStepEvent.emit();
             this.openSnackBar('Client Saved', 'success-snackbar');
          });
      }
      else{
        this.api.saveClient(this.client).subscribe((data: any) => {

          if (data.message == "Loan has been submitted and is awaiting approval" || data.message == "Client already has an active loan" || data.message ==  "Client loan has been approved") {
  
            let clientId = data.id;
  
            this.accountHolder = `${this.client.name} ${this.client.surname}`;
            sessionStorage.setItem('accountHolder', this.accountHolder);
            sessionStorage.setItem('ClientDetails', JSON.stringify(this.client));
            sessionStorage.setItem('clientId', data.id.toString());
            sessionStorage.setItem('username', `${this.client.surname.replace(/^[,;]*(.*?)[,; ]*$/, "$1")}${this.client.name.replace(/^[,;]*(.*?)[,; ]*$/, "$1")}`);
  
            this.api.GetFullClientInfoById(data.id).subscribe((data: any) => {
              if(data != null)
              {
                this.dataSharingService.setClientsInformation(data);
              }
        
            })

            if (this.consent == null) {
              this.consent = new Consent();
            }
  
            this.consent.clientId = clientId
            this.consent.loanId = 0;
            this.consent.popi = this.personalDetails.controls['popiaConsent'].value;
            this.consent.marketingMobile = this.personalDetails.controls['marketingConsent'].value;
            this.consent.creditCheck = this.personalDetails.controls['creditCheckConsent'].value;
  
            //sessionStorage.setItem("creditCheckResult", JSON.stringify(check));

            //saveClientAffordability
            this.api.saveConsent(this.consent).subscribe((consent: any) => {
              if (consent.message == "Success") {
                sessionStorage.setItem('ClientConsent', JSON.stringify(this.consent));
                this.updateStepEvent.emit();
                //loanapplciation method was here
              } else {
                this.openSnackBar(consent.message, 'error-snackbar');
                this.client = new SaveClientForCallback();
                this.consent = {} as Consent;
              }
            });
          } else {
            if(data.message)
            {
              this.openSnackBar(data.message, 'error-snackbar')
            }else{
              this.openSnackBar(data, 'error-snackbar');
            }
            
            const ClientTokenDetails = JSON.parse(sessionStorage.getItem("ClientTokenDetails"));

              setTimeout(function() {
                window.location.href = ClientTokenDetails.errorUrl
            }, 3000);

            return

            this.client = new SaveClientForCallback();
            this.consent = {} as Consent;
          }
        });
      }

    } else {
      this.openSnackBar("Please complete all required fields", 'error-snackbar');
    }
  }

  
  getClientInfo(mobileNumber: string , data: any) {
    this.api.GetFullClientInfo(mobileNumber).subscribe((data: any) => {
      if(data != null)
      {
        var json = JSON.stringify(data);
        sessionStorage.setItem('ClientInfo', json);
      }

    })
  }

  onKey(value: string) {
    this.selectedCountries = this.search(value);
  }

  search(value: string) {

    return this.countries.filter(item =>
      Object.keys(item).some(k => item[k] != null &&
        item[k].toString().toLowerCase()
          .includes(value.toLowerCase()))
    );
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  change(event: { value: number; }) {
    this.client = new SaveClientForCallback();
  }

  back(): void {
    this.previousStepEvent.emit();
  }

  checked(event: MatCheckboxChange) {
    this.canProceed = this.personalDetails.controls["popiaConsent"].value;
  }

  getSubdomain(url: string): string | null {
    try {
      const parsedUrl = new URL(url);
      const parts = parsedUrl.hostname.split('.');
      return parts.length >= 3 ? parts[0] : null;
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  }



  creditCheckConsent(consent:any)
  {
    if(consent.checked)
    {
      this.personalDetails.controls.creditCheckConsent.patchValue(false);
      let requestOtp:OtpLogin = {
        mobilenumber: this.personalDetails.controls["mobileNumber"].value,
        otp: '',
        id: 0
      };
      this.api.generateCreditCheckOtp(requestOtp).subscribe((data: any) => {
        requestOtp.id = data;
        this.openDialog(requestOtp);
      })
    }
  }
  

  openDialog(data: OtpLogin ,invalid: boolean = false): void {
    const dialogRef = this.dialog.open(OtpComponent, {
      width: '350px',
      data: { otp: "", OtpType: 'Consent to credit check', InvalidOtp: invalid, },
      disableClose: true
    });



    dialogRef.afterClosed().subscribe(result => {
      data.otp = result;

        this.api.validateLoginOtp(data).subscribe((result: any) => {
          if (!result == true) {
            this.openDialog(data,true);
          }else{
            this.personalDetails.controls.creditCheckConsent.patchValue(true);
          }
          
        });

    });
  }

      // this.api.saveClient(this.personalDetailsForm.value).pipe(delay(1500)).subscribe((data: any) => {
    //     this.loading = false;
    //     if (data) {
           
    //     }
    // });
  
}
