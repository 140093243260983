<div class="row">
    <div class="container-fluid">
        <div class="row mainDiv">
            <div class="col-md-6 col-12">
                <div class="card primary-card capture-card">
                    <div>
                       

                        <mat-tab-group [(selectedIndex)]="selectedIndex" mat-stretch-tabs (selectedTabChange)="onTabChange($event)">
                            <mat-tab>
                                <app-personal-details [clientAffordabilityDataEvent]="formDataEvent"
                                    [updateClient]="updateClient"
                                    [newLoan]="newLoan"
                                    [existingClient]="existingClient"
                                    [interestRate]="interestRate"
                                    (updateStepEvent)="updateStep()"
                                    ></app-personal-details>
                            </mat-tab>
                            <mat-tab #clientAddress label="">
                                <app-client-address (formSubmitted)="onFormSubmitted($event)"
                                    (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                                </app-client-address>
                            </mat-tab>
                            <mat-tab #clientAffordability label="">
                                <app-client-affordability (formSubmitted)="onFormSubmitted($event)" 
                                    (updateStepEvent)="updateStep()" (eventChange)="onChange()">
                              </app-client-affordability>
                            </mat-tab>
                            <mat-tab #bankEmployerDetails label="">
                                <!-- <app-bank-employer-details (updateStepEvent)="updateStep()">
                                </app-bank-employer-details> -->
                                <app-bank-details (updateStepEvent)="updateStep()"></app-bank-details>
                            </mat-tab>
                            <mat-tab #bankEmployerDetails label="">
                                <!-- <app-bank-employer-details (updateStepEvent)="updateStep()">
                                </app-bank-employer-details> -->
                                <app-employer-details (updateStepEvent)="updateStep()"></app-employer-details>
                            </mat-tab>
                            <mat-tab #uploadDocuments label="">
                                <app-upload-documents (updateStepEvent)="updateStep()"
                                    (previousStepEvent)="previousStep()">
                                </app-upload-documents>
                            </mat-tab>
                        </mat-tab-group>


                    </div>
                </div>

            </div>
            <div class="flowIcons desktop-only">
                <div>
                    <img *ngIf="selectedIndex >= 0" class="icon_margin" src="/assets/img/lndr/PersonalDetailsBlack.png">
                    <!-- <img *ngIf="personalDetails === false" class="icon_margin" src="/assets/img/lndr/PersonalDetailsWhite.png"> -->
                </div>
                <div>
                    <img *ngIf="selectedIndex >= 1" class="icon_margin" src="/assets/img/lndr/HomeAddressBlack.png">
                    <img *ngIf="selectedIndex < 1" class="icon_margin" src="/assets/img/lndr/HomeAddressWhite.png">
                </div>
                <div>
                    <img *ngIf="selectedIndex >= 2" class="icon_margin" src="/assets/img/lndr/AffordabilityBlack.png">
                    <img *ngIf="selectedIndex < 2" class="icon_margin" src="/assets/img/lndr/AffordabilityWhite.png">
                </div>
                <div>
                    <img *ngIf="selectedIndex >= 3" class="icon_margin" src="/assets/img/lndr/BankDetailsBlack.png">
                    <img *ngIf="selectedIndex < 3" class="icon_margin" src="/assets/img/lndr/BankDetailsWhite.png">
                </div>
                <div>
                    <img *ngIf="selectedIndex >= 4" class="icon_margin" src="/assets/img/lndr/EmploymentDetailsBlack.png">
                    <img *ngIf="selectedIndex < 4" class="icon_margin" src="/assets/img/lndr/EmploymentDetailsWhite.png">
                </div>
                <div>
                    <img *ngIf="selectedIndex >= 5" class="icon_margin" src="/assets/img/lndr/UploadDocumentsBlack.png">
                    <img *ngIf="selectedIndex < 5" class="icon_margin" src="/assets/img/lndr/UploadDocumentsWhite.png">
                </div>
            </div>
            <div class="stepBox desktop-only" *ngIf="!tabletGrid">
                <br>
                <br>
                <br>
                <h3 class="your-journey-font">Your Journey</h3>
                <div><p style="font-size: 16px;">Complete application in 10 minutes</p></div>
                <div class="journeyContent">
                    <label style="font-size: 16px;">Personal Details</label>
                    <input type="checkbox" disabled  [checked]="selectedIndex >= 0">
                </div>
                <div class="journeyContent">
                    <label style="font-size: 16px;">Home Address</label>
                    <input type="checkbox" disabled [checked]="selectedIndex >= 1" >
                </div>
                <div class="journeyContent">
                    <label style="font-size: 16px;">Affordability</label>
                    <input type="checkbox" disabled [checked]="selectedIndex >= 2">
                </div>
                <div class="journeyContent">
                    <label style="font-size: 16px;">Bank Details</label>
                    <input type="checkbox" disabled [checked]="selectedIndex >= 3">
                </div>
                <div class="journeyContent">
                    <label style="font-size: 16px;">Employment Details</label>
                    <input type="checkbox" disabled [checked]="selectedIndex >= 4">
                </div>
                <div class="journeyContent">
                    <label style="font-size: 16px;">Upload Documents</label>
                    <input type="checkbox" disabled [checked]="selectedIndex >= 5">
                </div>
            </div>
        </div>
       
        <div>
        
    </div>
        
    </div>
</div>

