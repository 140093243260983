import { AfterViewChecked, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarComponent } from 'app/snackbar/snackbar.component';
import { ApiService } from 'app/ts/ApiService';
import { DocumentUploadList, Products } from 'app/ts/models/broker-interfaces';
import { LoanApplication } from 'app/ts/models/loan-classes';
import { IdentificationTypeService } from 'app/ts/services/identification-type.service';
import { ProductSelectService } from 'app/ts/services/product-select.service';
import { environment } from 'environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { DataSharingService } from 'app/data-sharing-service';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss', '../../forms.shared.scss']
})
export class UploadDocumentsComponent implements OnInit, AfterViewChecked {
  @Output() updateStepEvent = new EventEmitter<number>();
  @Output() previousStepEvent = new EventEmitter<number>();

  productList$ = this._productSelect.productList;

  loanApplication: any = {};


  loanApplication$ = this._productSelect.loanApplication;
  isPassport$ = this._identityType.isPassport;

  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiURL;
  maximumFileSize = 40 * 1024 * 1024; // File size in MB

  selectedDocument: string;
  selectedDocumentDescription: string;
  documents: DocumentUploadList[];
  form: FormGroup;
  loanType: string;
  clientId: number = 0;
  staffId: number = 0;
  clientProfile: any;
  documentSelected: DocumentUploadList;
  isLoading = false;
  existingClient:boolean = false;

  constructor(private api: ApiService,
    private snackbar: MatSnackBar,
    private router: Router,
    public formBuilder: FormBuilder,
    public _productSelect: ProductSelectService,
    public _identityType: IdentificationTypeService,
    private dataSharingService: DataSharingService) {

    this._productSelect.loanApplication$.subscribe((x: LoanApplication) => {
      if (x !== undefined || x !== null) {
        if (this.loanApplication$ != x) {
          this.loanApplication$ = x;
          this.setDocumentList();
        }
      }
    });

    this._identityType.isPassport$.subscribe((x: null) => {
      if (x !== undefined || x !== null) {
        if (this.isPassport$ != x) {
          this.isPassport$ = x;
          this.setDocumentList();
        }
      }
    });

    this.setDocumentList();

    this.selectDocument(0);

    this.getDocuments();
  }
  getDocuments() {
    this.clientProfile = JSON.parse(sessionStorage.getItem('ClientInfo'));
    if (this.clientProfile != null && this.clientProfile.documents.length > 0) {
      if (this.clientProfile.idDocumentUploaded) {
        this.documents[0].isUploaded = true;
      }

      for (const uploadedKey of this.clientProfile.documents) {
        const documentToUpdate = this.documents.find(doc => doc.key === uploadedKey.description);

        if (documentToUpdate) {
          documentToUpdate.isUploaded = true;
        }
      }

      // Find the index of the first document that is not uploaded
      const firstNotUploadedIndex = this.documents.findIndex(doc => !doc.isUploaded);

      if (firstNotUploadedIndex !== -1) {
        this.selectDocument(firstNotUploadedIndex);
      }

    }
  }

  setDocumentList() {
    const returningClient = JSON.parse(sessionStorage.getItem("ClientInfo"));

    if (returningClient) {
        this.existingClient = true;
        const returnDocs = returningClient.documents;

        const hasBankStatements = returnDocs.some(document => document.description === "bankStatements");
        if (!hasBankStatements) {
            this.documents = [
                { key: 'id', description: 'ID Copy', isUploaded: false, uploadDetails: null },
                { key: 'bankstatement', description: '30 Days Bank Statement', isUploaded: false, uploadDetails: null },
                { key: "payslip", description: 'Latest Payslip', isUploaded: false, uploadDetails: null },
                { key: 'additional', description: 'Additional Forms', isUploaded: false, uploadDetails: null }
            ];
        } else {
            this.documents = returnDocs;
        }
    } else {
        this.documents = [
            { key: 'id', description: 'ID Copy', isUploaded: false, uploadDetails: null },
            { key: 'bankstatement', description: '3 Months Bank Statements', isUploaded: false, uploadDetails: null },
            { key: "payslip", description: 'Latest Payslip', isUploaded: false, uploadDetails: null },
            { key: 'additional', description: 'Additional Forms', isUploaded: false, uploadDetails: null }
        ];
    }
}

  ngAfterViewChecked(): void {

    if (this.clientId == 0) {
      if (Number(sessionStorage.getItem('clientId')) > 0) {
        this.clientId = Number(sessionStorage.getItem('clientId'));
        this.uploader.options.url = `${this.baseUrl}Portal/upload-client-documents/${this.clientId}/${this.staffId}/${this.selectedDocument}`
      }

    }
  }

  ngOnInit(): void {
    
  }

  initializeUploader() {
    this.clientId = Number(sessionStorage.getItem('clientId'));
    this.staffId = Number(sessionStorage.getItem("staffId"));

    this.uploader = new FileUploader({
      url: `${this.baseUrl}Portal/upload-client-documents/${Number(sessionStorage.getItem('clientId'))}/${Number(sessionStorage.getItem("staffId"))}/${this.selectedDocument}`,
      isHTML5: true,
      allowedFileType: ['pdf', 'image'],
      removeAfterUpload: false,
      autoUpload: true,
      maxFileSize: this.maximumFileSize,
    });

    this.uploader.onProgressItem = (file) => {
      let index = this.uploader.queue.indexOf(file);
      this.uploader.queue[index].progress = 0;
      this.uploadFilesSimulator(index, this.uploader.queue);
    }




    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (status = 204) {
        this.openSnackBar(`${this.selectedDocumentDescription} Successfully uploaded.`, "success-snackbar");
        let updateItem = this.documents.find(this.findIndexToUpdate, this.selectedDocument);
        let index = this.documents.indexOf(updateItem);

        this.documents[index].isUploaded = true;
        this.documents[index].uploadDetails = this.uploader;
        if (this.isLastDocument(index)) {
          // if it's the last document, automatically trigger the finish method
          this.finish();
        } else {
          // Find the next document that is not uploaded
          const nextIndex = this.findNextNotUploadedDocument(index);
          if (nextIndex !== -1) {
            this.selectDocument(nextIndex);
          } else {
            this.finish();
          }
        }
      }
    }

    this.uploader.onErrorItem = (item, response, status, headers) => {
      let message = `Could not upload the selected ${this.selectedDocumentDescription}`;

      if (response) {
        message = `${message}, ${response}`;
      }

      this.openSnackBar(message, "error-snackbar");
    }

    // this.uploader.response.subscribe(i => this.response)

  }

  isLastDocument(index: number): boolean {
    return index === this.documents.length - 1;
  }

  findNextNotUploadedDocument(currentIndex: number): number {
    for (let i = currentIndex + 1; i < this.documents.length; i++) {
      if (!this.documents[i].isUploaded) {
        return i;
      }
    }
    return -1; // Return -1 if no more documents need to be uploaded
  }

  selectDocument(i: number) {
    this.selectedDocument = this.documents[i].key;
    this.selectedDocumentDescription = this.documents[i].description;
    this.documentSelected = this.documents[i];
    this.initializeUploader()
  }

  fileOverBase(e: any) {
    this.hasBaseDropZoneOver = e;
  }

  findIndexToUpdate(newItem) {
    return newItem.key === this;
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number, fileArray: any[]) {

    setTimeout(() => {
      if (index === fileArray.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (fileArray[index].progress === 100) {
            console.log("here?");
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1, fileArray);
          } else {
            console.log("progress2", fileArray[index].progress)
            fileArray[index].progress += 5;
          }
        }, 50);
      }
    }, 1000);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  back(): void {
    this.previousStepEvent.emit();
  }

  submitLoanApplication(clientId: number): void {
    const clientDetails = JSON.parse(localStorage.getItem("clientDetailsData"));
    const selectedProduct = JSON.parse(localStorage.getItem("selectedProduct"));
    this.loanApplication.clientId = clientId;
    this.loanApplication.username = `${clientDetails.surname.replace(/^[,;]*(.*?)[,; ]*$/, "$1")}${clientDetails.name.replace(/^[,;]*(.*?)[,; ]*$/, "$1")}`;
    this.loanApplication.amount = Math.round(Number(clientDetails.loanamount));
    this.loanApplication.term = clientDetails.loanterm;
    this.loanApplication.type = "individual-personal";
    this.loanApplication.selectedProduct = "";
    //this.loanApplication.productId = clientDetails.selectedProduct.id;

    this.loanApplication.productId = selectedProduct.id;

    this.api.submitLoanApplication(this.loanApplication).subscribe(
      (data: any) => {
        if (data.loanId !== 0 && data.loanId !== undefined) {
          this.dataSharingService.setLoanApplication(this.loanApplication);
          this.openSnackBar("Loan submitted", 'success-snackbar');
        } else {
          this.openSnackBar(data, 'error-snackbar');
        }
      },
      (error) => {
        this.openSnackBar("Error submitting loan application", 'error-snackbar');
      }
    );
  }



  // getClientProducts() {
  //   this.staffId = 718;
  //   this._productSelect
  //     .getStaffProductList(this.staffId.toString())
  //     .subscribe((data: Products[]) => {
  //       const selectedProduct = this.findSelectedProduct(data);
        
  //       localStorage.setItem("selectedProduct", JSON.stringify(selectedProduct));
  //       if (selectedProduct) {
  //         this.updateSelectedProduct(selectedProduct);
  //       }
  //     });
  // }

  // findSelectedProduct(data: Products[]): Products | undefined {
  //   if (this.interestRate === 0.03) {
  //     return data[0];
  //   } else {
  //     return data[1]
  //   }
  //   // if (!this.productId) {
  //   //     return data.length > 0 ? data[1] : undefined;
  //   // }
    
  //   // return data.find((item) => Number(this.productId) === item.id);
  // }
  
  // updateSelectedProduct(selectedProduct: Products) {
  //   if(selectedProduct)
  //   {
  //     this.selectedProduct = selectedProduct;
  //     this.maxmumTerm = selectedProduct.maxNumberOfRepayments;
  //     this.minmumTerm = selectedProduct.minNumberOfRepayments;
    
  //     this.personalDetails.patchValue({
  //       loanterm: this.maxmumTerm,
  //     });
  //   }

  // }


  finish(): void {
    if (this.docValidationId() && this.docValidationBankStatement() && this.docValidationPayslip()) {
      this.isLoading = true;
      this.api.sendUlEmail(Number(sessionStorage.getItem("staffId")), Number(sessionStorage.getItem("clientId"))).subscribe(() => {
        // this.openSnackBar('Congratulations you have successfully submitted your application', 'success-snackbar');
        // this.router.navigate(['success'], ); Number(sessionStorage.getItem("staffId")


        const clientToken = JSON.parse(sessionStorage.getItem('ClientTokenDetails'));

        const clientId = Number(sessionStorage.getItem('clientId'));
        // submit loan application here
        this.submitLoanApplication(clientId);

        this.api.doCreditCheck(Number(sessionStorage.getItem('clientId'))).subscribe((check: any) => {
          // window.location.href = clientToken.successUrl;
          this.router.navigate(['/broker/client-capture-final']);
        })

      });
      //this.updateStepEvent.emit()
    } else {
      if (!this.docValidationId()) {
        this.openSnackBar('Please make sure the ID or Passport documents are uploaded.', 'error-snackbar');
      }
      if (!this.docValidationBankStatement()) {
        this.openSnackBar('Please make sure the Bank Statement document is uploaded.', 'error-snackbar');
      }
      if (!this.docValidationPayslip()) {
        this.openSnackBar('Please make sure the Payslip document is uploaded.', 'error-snackbar');
      }
      this.isLoading = false;

    }


  }

  docValidationId() {
    let result: Boolean = false;
    this.documents.forEach(doc => {
      if (doc.key == 'id') {
        if (doc.isUploaded) {
          result = true;
        }
      }
    })
    return result;
  }

  docValidationBankStatement() {
    let result: Boolean = false;
    this.documents.forEach(doc => {
      if (doc.key == 'bankstatement') {
        if (doc.isUploaded) {
          result = true;
        }
      }
    })
    return result;
  }

  docValidationPayslip() {
    let result: Boolean = false;
    this.documents.forEach(doc => {
      if (doc.key == 'payslip') {
        if (doc.isUploaded) {
          result = true;
        }
      }
    })
    return result;
  }

  openSnackBar(msg: string, panel: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: msg,
      panelClass: [panel],
      duration: 8000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
