<nav class="navbar-expand-lg navbar-absolute fixed-top" >
    <div class="container-fluid">       
        <div class="justify-content-around" style="margin: 0px 7%" id="navigation">
            <div class="row test">
                <div class="col-7 left">
                    <div style="width: 85%">
                        <img class="img-style" src="/assets/img/lndr/lndr-logo-reverse.png" />
                    </div>
                    <div style="max-height: 50px; text-transform: none !important; margin-right:40px;">
                        <div class="col-md-7 pt-1">
                            <div style="width: 100%; margin-right:10%; text-align: right;">
                                <div class="row">
                                    <div class="col-md-12 p-0 loading_icon" class="" style="font-size: larger;">
        
                                        <!-- <img class="icon_margin" style="height: 45px;" src="/assets/img/uloans/profile.svg"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
                <div class="col-5 comp">
                    <ul class="navbar-nav pb-4">
                        <li class="desktop-only" *ngIf="this.authenticated" (click)="updateClientRoute()">
                            <img class="icon_margin" src="/assets/img/lndr/ClientProfileGreen.png"> Client Profile
                        </li>
                        <li class="desktop-only" *ngIf="this.authenticated" (click)="navNavigation('/broker/loans')">
                            <img class="icon_margin listSpace" src="/assets/img/lndr/LoansGreen.png"> Loans
                        </li>
                        <li class="desktop-only" *ngIf="this.authenticated" (click)="logout()">
                            <img class="icon_margin" src="/assets/img/lndr/Logout.png"> Sign Out
                        </li>
                        <li class="mobile-font" *ngIf="this.authenticated === null" (click)="navNavigation('/login')">
                            <img class="icon_margin listSpace" src="/assets/img/lndr/LoansGreen.png">Login
                        </li>                        
                        <li style="width: 100% !important;">
                            <button *ngIf="this.authenticated" (click)="newLoan()" class="loanBtn">New Loan</button>
                        </li>
                    </ul>
                </div>
                              
            </div>            
        </div>
    </div>
</nav>
